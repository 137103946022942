import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { useMutation } from 'react-query';

import {
    grid,
    container,
    heading,
    contact,
    subtitle,
    button,
} from './company-categories.module.scss';

import { IQueryAllResult } from '../models/query-all-result.model';
import { ICompanyCategory } from '../models/company-category.model';
import { getNodes } from '../utils/get-nodes';
import { searchCompanyCategories } from '../api-ssr/search';
import { ISearchCompanyCategories } from '../models/search-results.model';
import useTranslations from '../hooks/use-translations';
import useQueryParam from '../hooks/use-query-param';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import CompanyCategoriesGroupList from '../components/molecules/company-categories-group-list';
import ContactSuggestion from '../components/molecules/contact-suggestion';
import Button from '../components/atoms/button';

interface ICompanyCategoriesQueryResults {
    allCompanyCategory: IQueryAllResult<ICompanyCategory>;
    companyRegisterPage: Pick<IPage, 'pathname'> | null;
}

interface ICompanyCategoriesProps {
    data: ICompanyCategoriesQueryResults;
}

const CompanyCategories: React.FC<ICompanyCategoriesProps> = ({ data }) => {
    const t = useTranslations('CompanyCategories');
    const { companyRegisterPage, allCompanyCategory } = data;
    const companyCategories = getNodes(allCompanyCategory);
    const [companyCategoriesData, setCompanyCategoriesData] = useState(companyCategories);
    const [isEmpty, setIsEmpty] = useState(false);

    const searchParam = useQueryParam('phrase');
    const searchParamValue = searchParam.values[0];

    const mutation = useMutation(searchCompanyCategories, {
        onSuccess: (data: ISearchCompanyCategories[]) => {
            setIsEmpty(data.length < 1);
            setCompanyCategoriesData((prevCategories) => {
                return prevCategories.map((category) => {
                    const found = data.find((result) => {
                        return result.id === category.categoryId;
                    });
                    return { ...category, active: !!found };
                });
            });
        },
    });

    const { isLoading, isError } = mutation;

    const resetResults = () => {
        setIsEmpty(false);
        setCompanyCategoriesData((prevCategories) => {
            return prevCategories.map((category) => {
                return { ...category, active: undefined };
            });
        });
    };

    useEffect(() => {
        if (searchParamValue) {
            mutation.mutate({ phrase: searchParamValue });
        } else {
            resetResults();
        }
    }, [searchParamValue]);

    const handleSubmit = (searchQuery: string) => {
        if (searchQuery) {
            searchParam.setValue(searchQuery);
        } else {
            searchParam.removeValue();
        }
    };

    return (
        <MainLayout
            additionalSearch={{
                type: 'company-category',
                handleSubmit: handleSubmit,
                phrase: searchParamValue,
            }}
        >
            <div className={grid}>
                <div className={container}>
                    {companyRegisterPage && (
                        <Button
                            as="link"
                            to={companyRegisterPage.pathname}
                            isOutlined={true}
                            theme="accent"
                            className={button}
                        >
                            {t.button}
                        </Button>
                    )}
                    <Heading numberOfStyle={2} Tag={'h1'} className={heading}>
                        {t.heading}
                    </Heading>
                    <h2 className={subtitle}>
                        {isError ? t.error : isEmpty ? t.empty : t.subtitle}
                    </h2>
                    <CompanyCategoriesGroupList
                        isLoading={isLoading}
                        companyCategories={companyCategoriesData}
                    />
                    <ContactSuggestion className={contact} />
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($locale: String!) {
        allCompanyCategory(filter: { isEmpty: { eq: false } }) {
            edges {
                node {
                    ...companyCategoryFields
                }
            }
        }

        companyRegisterPage: page(locale: { eq: $locale }, type: { eq: "company-register" }) {
            pathname
        }
    }
`;

export default CompanyCategories;
