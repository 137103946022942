import React from 'react';

import { wrapper, description, header, button } from './contact-suggestion.module.scss';

import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import Content from '../atoms/content';
import Button from '../atoms/button';

interface IContactSuggestionProps {
    className?: string;
}

const ContactSuggestion: React.FC<IContactSuggestionProps> = ({ className = '' }) => {
    const t = useTranslations('ContactSuggestion');

    return (
        <div className={`${wrapper} ${className}`}>
            <Heading className={header} numberOfStyle={3}>
                {t.header}
            </Heading>
            <Content className={description} numberOfStyle={2}>
                {t.description}
            </Content>
            <Button
                as={'link'}
                to={'/contact/'}
                className={button}
                theme={'accent'}
                isOutlined={true}
            >
                {t.button}
            </Button>
        </div>
    );
};

export default ContactSuggestion;
